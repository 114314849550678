@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import "@radix-ui/colors/black-alpha.css";
@import "@radix-ui/colors/violet.css";

/* reset */
button {
  all: unset;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
}

:root {
  --text: rgb(14, 14, 17);
}

* {
  border: 0px;
  -webkit-font-smoothing: antialiased;
  margin: 0px;
  outline: 0px;
  padding: 0px;
  text-rendering: optimizelegibility;
}

p,
span,
label {
  margin: 0px;
  text-align: start;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 24px;
  color: var(--text);
  font-size: 1rem;
}

.radix-select p,
.radix-select span,
.radix-select label {
  color: inherit; /* Or any other color you want */
}

#root {
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ccc;
  overflow: hidden;
}

.App {
  background-color: white;
  max-width: 450px;
  aspect-ratio: 400 / 650;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
}

.CheckboxRoot {
  background-color: white;
  min-width: 25px;
  min-height: 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px var(--black-a7);
}
.CheckboxRoot:hover {
  background-color: var(--violet-3);
}
.CheckboxRoot:focus {
  box-shadow: 0 0 0 2px black;
}

.CheckboxIndicator {
  color: var(--violet-11);
}

::-webkit-scrollbar {
  height: 1rem;
  width: 0.5rem;
}
.dark ::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 100%, 0.1);
}
::-webkit-scrollbar-thumb {
  --tw-border-opacity: 1;
  background-color: rgba(217, 217, 227, 0.8);
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 1px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 9999px;
}
